import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AppRoutes } from "../../settings/routes";
import { EventStorageService } from "../../services/event-storage.service";
import { IEventModel } from "../../models/event.model";

@Component({
	selector: "event-header",
	templateUrl: "event-header.component.html"
})
export class EventHeaderComponent implements OnInit {
	@Input() event: IEventModel;

	showUserPanel: boolean;

	constructor(
		private eventStorageService: EventStorageService,
		private router: Router
	) { }

	ngOnInit() { this.showUserPanel = this.eventStorageService.showUserPanel; }


	navigateEventList() { this.router.navigate([AppRoutes.event.path, this.event.id, AppRoutes.event.id.subsampleList.path]); }
	navigateEventSettings() { this.router.navigate([AppRoutes.event.path, this.event.id, AppRoutes.event.id.surveyEventSettings.path]); }

	toggleUsePanel() {
		this.showUserPanel = !this.showUserPanel;
		this.eventStorageService.showUserPanel = this.showUserPanel;
	}
}
