import { ArrayExtensions } from "../utilities/array.extensions";
import { StringExtensions } from "../utilities/string.extensions";
import { AppSettings } from "../settings/app.settings";

import { UserTypeEnum } from "../enums/user-type.enum";
import { IUserRoleModel } from "./user-role.model";

export interface IUserModel {
  companyId?: string;
  employeeId?: string;
  departmentId?: number;
  // managerId?: number;
  // isAssignmentManager?: boolean;
  // isSurveyCreatorUser?: boolean;
  employeeDirectoryLink?: string;
  employeeDirectoryId?: number;
  userId?: number;
  name?: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  type?: UserTypeEnum;
  roles?: IUserRoleModel;
  // createDate?: Date;
  // lastUpdateDate?: Date;
  email?: string;
  // alertsEmail?: string;
  // passwordChangeDate?: Date;
  timeZoneId?: number;
  // visible?: boolean;
  groups?: string[];
  displayName?: string;
  properName?: string;
  timeZoneOffset?: string;
  isInRouterManagementGroup?: boolean;
}

export class UserModel implements IUserModel {
  companyId: string = "";
  employeeId: string = "";
  departmentId: number = 0;
  // managerId: number = 0;
  // isAssignmentManager: boolean = false;
  // isSurveyCreatorUser: boolean = false;
  employeeDirectoryLink: string = "";
  employeeDirectoryId: number = 0;
  userId: number = 0;
  name: string = "";
  fullName: string = "";
  firstName: string = "";
  lastName: string = "";
  type: UserTypeEnum.Admin;
  roles: IUserRoleModel;
  // createDate: Date = new Date(0);
  // lastUpdateDate: Date = new Date(0);
  email: string = "";
  // alertsEmail: string = "";
  // passwordChangeDate: Date = new Date(0);
  timeZoneId: number = 0;
  // visible: boolean = false;
  groups: string[] = [];
  timeZoneOffset: string = "";
  isInAccessGroup: boolean = false;
  isInScriptingGroup: boolean = false;
  isInSuperUserGroup: boolean = false;
  isInRouterManagementGroup: boolean = false;

  get displayName() {
    let arr = (this.fullName || "").split(",");
    return arr.length > 1 ? String(arr[arr.length - 1]).trim() : this.fullName;
  }

  get properName() {
    return (this.fullName || "").replace(/\,/g, " ").replace(/\s+/g, " ");
  }

  constructor(private appSettings: AppSettings, model?: IUserModel) {
    ArrayExtensions.deepCopy.apply(this, [model]);
    this.fullName = (this.fullName || "").trim();
    this.firstName = (this.firstName || "").trim();
    this.lastName = (this.lastName || "").trim();
    this.isInAccessGroup = (this.groups.filter(x => x.toLowerCase() === this.appSettings.AccessGroup.toLowerCase()) || []).length === 1;
    this.isInScriptingGroup = (this.groups.filter(x => x.toLowerCase() === this.appSettings.ScriptingGroup.toLowerCase()) || []).length === 1;
    this.isInSuperUserGroup = (this.groups.filter(x => x.toLowerCase() === this.appSettings.SuperUserGroup.toLowerCase()) || []).length === 1;
    this.isInRouterManagementGroup = (this.groups.filter(x => x.toLowerCase() === this.appSettings.RouterManagementGroup.toLowerCase()) || []).length === 1;

    // this.visible = this.fullName.length > 0;
    if (!this.employeeDirectoryLink) {
      this.employeeDirectoryLink = this.appSettings.DefaultEmployeeImage;
    } else if (!new RegExp(this.employeeDirectoryLink, "i").test(this.appSettings.DefaultEmployeeImage)) {
      this.employeeDirectoryLink =
        StringExtensions.formatByName(this.appSettings.EmployeeImage, { "imagePath": "" }) +
        (
          (this.employeeDirectoryLink || "").length === 0
            ? this.appSettings.DefaultEmployeeImage
            : this.employeeDirectoryLink
        );
    }
  }
}
