import { SurveyStatusEnum, SurveyStatusList, SurveySubsampleCreateSourceEnum } from "../enums/survey-status.enum";
import { ILanguageModel } from "./language.model";
import { IEsenderNotificationTemplateModel } from "./esender-notification-template.model";
import { IEntryLinkModel } from "./entry-link.model";
import { ISubsampleFieldMonitoringInfoModel } from "./subsample-field-monitoring-info.model";
import { ISurveySecurityProfileModel } from "./survey-security.model";
import { ArrayExtensions } from "../utilities/array.extensions";
import { IAutoIncrementScheduleModel } from "./auto-increment-schedule.model";
import { IDictionary } from "./../interfaces/dictionary.interface";

export interface ISubsampleModel {
  id?: number;
  name?: string;
  nameShownToSuppliers?: string;
  supplierComment?: string;
  status?: string;
  statusId?: SurveyStatusEnum;
  startTime?: Date;
  endTime?: Date;
  languages?: ILanguageModel[];
  firstTimeStatusLiveDate?: Date;

  priority?: number;
  addToMainRouterFlag?: boolean;
  jobNumberId?: number;
  standAlone?: number;
  surveyPlatform?: string;
  showLinks?: boolean;
  surveyEventId?: number;
  targetCompletes?: number;
  expectedLOI?: number;
  expectedIncidence?: number;
  expectedConversionRate?: number;
  expectedAbandonRate?: number;
  expectedCPI?: number;
  expectedCPICurrency?: string;
  isProduceReallocation?: boolean;
  produceReallocationToPickerGroupId?: number;
  produceReallocationToPickerGroupName?: string;
  isAcceptSampledRespondents?: boolean;
  isAcceptReallocatedRespondents?: boolean;
  acceptReallocationGroups?: IDictionary<string>;
  allowsEditOnSampleOptimization?: boolean;
  isEnableWebsiteNotifications?: boolean;
  isEnableEmailNotifications?: boolean;
  inviteTemplates?: IEsenderNotificationTemplateModel[];
  isEnableEmailReminders?: boolean;
  reminderTemplates?: IEsenderNotificationTemplateModel[];
  isEnableMobileNotification?: boolean;
  surveyLiveLink?: string;
  surveyTestLink?: string;
  links?: IEntryLinkModel[];
  info?: ISubsampleFieldMonitoringInfoModel;
  securityProfiles?: ISurveySecurityProfileModel[];
  supplierId?: number;
  isEmailNotificationInviteEditable?: boolean;
  isEmailNotificationReminderEditable?: boolean;
  sampleCategory?: number;
  sampleSubcategory?: number;
  statusCssColor?: string;
  sampleOneSampleOnly?: boolean;
  createSource?: string;
  createSourceId?: SurveySubsampleCreateSourceEnum;
  countryId?: number;
  maxReallocationPercent?: number;
  collectsPII?: boolean;
  facialCoding?: boolean;
  isApiCompatible?: boolean;
  disableSupplierReason?: Array<string>;
  researchTypeId?: number;
  researchTypeName?: string;
  researchTypeReadFromEvent?: boolean;
  autoIncrementQuotasFlag?: boolean;
  autoIncrementSchedule?: IAutoIncrementScheduleModel;
  minFCValidPercent?: number;
  feasibilityId?: string;
  minFCValidCompletes?: number;
  lowestQuotaCell?: number;
  panelOneEnabled?: boolean;
  sampleQuotaModeId?: number;
  respondentList?: boolean;
  skipPrescreening?: boolean; 
  progressThresholdId?: number;
  receiveOnlyMatchingRespondents?: boolean;
  statusLockedFlag?: boolean;
  manuallyCreatedFlag?: boolean;
  exemptedFromBeingPausedOrLocked?: boolean;
  needRecontact?: boolean;
  isSampled?: boolean;
  isPotential?: boolean;
  isCritical?: boolean;
  isMatching?: boolean;
  isNewRecruits?: boolean;
  requiresFixedSampleBlend?: boolean;
  requiresPacing?: boolean;
  isRecontact?: boolean; // from Profiles Allowed in Internal sample settings tab
  useAutomatedBlendFlag?: boolean;
  useAutomatedBlendOptionId?: number;
  stopAutomatedBlendFlowFlag?: boolean;
  reasonForSurveyUsesAutomatedBlendChange?: string;
  statusLockedCount?: number;
  dataguardEnabled?: boolean;
  useDigestV2Flag?: boolean;
  pushSubsampleOnPendingAtDailyTargetCompletion?: boolean;
}

export class SubsampleModel implements ISubsampleModel {
	status?: string;
	statusId?: SurveyStatusEnum;
	statusCssColor?: string;
	createSource?: string;
	createSourceId?: SurveySubsampleCreateSourceEnum;
	links?: IEntryLinkModel[];
	autoIncrementSchedule?: IAutoIncrementScheduleModel;

	constructor(model?: ISubsampleModel) {
		ArrayExtensions.deepCopy.apply(this, [model]);

		//Seems to be a bug with the deep copy function in that it's not correctly deep copying Date objects. Will manually deep copy the dates here for now.
		//Will probably need to fix the deep copy function at some point.
		/*
		if (model && this.autoIncrementSchedule && model.autoIncrementSchedule) {
			this.autoIncrementSchedule.startTime = new Date(model.autoIncrementSchedule.startTime.valueOf());
			this.autoIncrementSchedule.endTime = new Date(model.autoIncrementSchedule.endTime!.valueOf());
		}
		*/

		switch ((this.status || "").toLowerCase()) {
			case "pending":
				this.statusId = SurveyStatusEnum.Pending;
				break;
			case "live":
				this.statusId = SurveyStatusEnum.Live;
				break;
			case "complete":
				this.statusId = SurveyStatusEnum.Complete;
				break;
			case "canceled":
				this.statusId = SurveyStatusEnum.Canceled;
				break;
			case "testing":
				this.statusId = SurveyStatusEnum.Testing;
				break;
			default:
				this.statusId = SurveyStatusEnum.None;
				break;
		}
		switch ((this.createSource || "").toLowerCase()) {
			case "cortexlegacy":
				this.createSourceId = SurveySubsampleCreateSourceEnum.CortexLegacy;
				break;
			case "sampleonly":
				this.createSourceId = SurveySubsampleCreateSourceEnum.SampleOnly
				break;
			case "fullservice":
				this.createSourceId = SurveySubsampleCreateSourceEnum.FullService;
				break;
			case "fullservicerapid":
				this.createSourceId = SurveySubsampleCreateSourceEnum.FullServiceRapid;
				break;
			case "fullservicelegacy":
				this.createSourceId = SurveySubsampleCreateSourceEnum.FullServiceLegacy;
				break;
			case "fullservicedigital":
				this.createSourceId = SurveySubsampleCreateSourceEnum.FullServiceDigital;
				break;
			case "sampleonlydigital":
				this.createSourceId = SurveySubsampleCreateSourceEnum.SampleOnlyDigital;
				break;
			default:
				this.createSourceId = SurveySubsampleCreateSourceEnum.CortexLegacy;
				break;
			//TODO
		}
		this.statusCssColor = SubsampleModel.getSurveyStatusColor(this.statusId);
	}

	private static getSurveyStatusColor(statusId: SurveyStatusEnum): string {
		for (let item of SurveyStatusList) {
			if (item.id === statusId) {
				return item.colorClass;
			}
		}
		return "default";
	}
}
